// React
import React from 'react';
import { useHistory } from 'react-router-dom';

// Styling and Semantics
import {
    Button,
    Modal,
} from 'semantic-ui-react';

/**
 *  Unavailable Component
 */
const Unavailable = () => {
    // State hooks
    const history = useHistory();

    const handleCancelClick = () => {
        history.push('/');
    }

    return (
        <Modal
            size='tiny'
            open={true}
        >
            <Modal.Header>Feature Unavailable</Modal.Header>
            <Modal.Content>
                <p>This feature is not available at this time.</p>
                <p>Please watch Discord for updates.</p>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={() => handleCancelClick()}>
                    Okay
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default Unavailable;
