// React
import React from 'react';

// Libraries
import { get, map, round } from 'lodash';
import numeral from 'numeral';

// Styling and Semantics
import {
    Form,
    Header,
    Message,
    Table,
} from 'semantic-ui-react'

/**
 *  Deliver/Valuation Component
 */
const Valuation = ( props ) => {
    const { formReady, formData, validateForm } = props;

    const handleSubmitForApproval = (e, data) => {
        validateForm();
    }

    // After any update, we recalcuate the valuation for this contract.
    const values = map(formData.deliverables, (deliver) => round(numeral(get(deliver, 'net')).value(), 4));
    const total = values.reduce((a, b) => a + b, 0);
    const valuation = total > 0 ? `${numeral(total).format('0,0[.][0000]')} ${formData.currency}` : '-';

    return (
        <div>
            <Header as='h1'>Valuation</Header>
            <Table celled compact size='large'>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell className='balance-positive huge-text'>{valuation}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell>
                            <Form.Button
                                loading={!formReady}
                                fluid
                                primary
                                className='button-submit'
                                size='massive'
                                onClick={handleSubmitForApproval}
                            >Submit For Approval</Form.Button>
                            {get(formData, 'errors.message') ? (
                                <Message
                                    error
                                    header='Missing or Invalid Entry'
                                    content={get(formData, 'errors.message')}
                                />
                            ) : ''}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    );
}

export default Valuation;
