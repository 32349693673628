// React
import React from 'react';

// Libraries
import { capitalize, get } from 'lodash';
import numeral from 'numeral';

// Styling and Semantics
import {
    Header,
    Table,
} from 'semantic-ui-react'

const statusDescription = {
    open: 'Order is awaiting review.',
    cancelled: 'Pilot has cancelled the order.',
    rejected: 'Order has been rejected.',
    received: 'Order has been approved, awaiting payout.',
    paid: 'Order has been approved.  Pilot has been paid.',
};

/**
 *  Order/Valuation Component
 */
const Valuation = ( props ) => {
    const { orderData } = props;
    const cellClass = `order-status-${orderData.status}`;
    return (
        <div>
            <Header as='h1'>Purchase Receipt</Header>
            <Table celled compact definition size='large'>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Total Cost</Table.Cell>
                        <Table.Cell className='balance-positive'>
                            {numeral(orderData.valuation).format('0,0[.][0000]')} {orderData.currency}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Order Status</Table.Cell>
                        <Table.Cell className={cellClass}>{capitalize(orderData.status)}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Description</Table.Cell>
                        <Table.Cell>{get(statusDescription, orderData.status)}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </div>
    );
}

export default Valuation;
