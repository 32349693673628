// External Libraries
import styled from 'styled-components/macro';

// Semantics
import {
    Segment
} from 'semantic-ui-react';

export const SoloSegment = styled(Segment)`
    padding-top: 100px !important;
    text-align: center;
`;
