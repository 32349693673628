// React
import React, { useEffect, useState } from 'react';

// Libraries
import {
    find, isEmpty, map, sortBy
} from 'lodash';
import { toast } from 'react-toastify';

import API from '../../lib/api';

// Styling and Semantics
import {
    Form,
    Grid,
    Header,
} from 'semantic-ui-react';

// Components
import PGridRows from './PGridRows';
//import PTableMobile from './PTableMobile';

/**
 *  Pilot Component
 */
const Pilot = ( props ) => {
    // Property Descructure
    const { config: { corp, isMobile, setCrumbs } } = props;
    const { id } = props.match.params;

    // State hooks
    const [pilotData, setPilotData] = useState([]);
    const [currenciesData, setCurrenciesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [ledgerData, setLedgerData] = useState([]);
    const [currency, setCurrency] = useState();

    // Effect hooks
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCrumbs([
            { text: 'Home', to: '/' },
            { text: 'Dashboard', to: '/dashboard' },
            { text: 'Pilot Ledger'}
        ]);
    }, [setCrumbs]);

    useEffect(() => {
        if (!isEmpty(corp)) {
            API.getCurrencies(
                corp,
                (currencies) => {
                    setCurrenciesData(currencies);
                    const defaultCurrency = find(currencies, {'is_default': 1});
                    if (!isEmpty(defaultCurrency)) {
                        setCurrency(defaultCurrency.name);
                    }
                },
                (error) => toast.error('Unknown error getting currencies.')
            );
            API.getLedger(
                corp, id,
                (ledger) => setLedgerData(ledger),
                (error) => toast.error('Unknown error getting ledger data.')
            );
            API.getPilot(
                corp, id,
                (pilot) => setPilotData(pilot),
                (error) => toast.error('Unknown error getting pilot data.')
            );
        }
    }, [corp, id]);

    useEffect(() => {
        const newLedgerData = [];
        ledgerData.forEach((ledger) => {
            if (ledger.currency === currency) {
                newLedgerData.push(ledger);
            }
        });
        setFilteredData(newLedgerData);
    }, [ledgerData, currency]);

    const currencyOptions = sortBy(map(currenciesData, (coin) => {
        let coinLabel = `[${coin.name}]`;
        if (!isEmpty(coin.description)) {
            coinLabel += ` - ${coin.description}`;
        }
        return {
            key: coin.id,
            text: coinLabel,
            value: coin.name,
        };
    }), ['text']);

    const handleCurrencyChange = (e, data) => setCurrency(data.value);

    const pGridRows = isMobile ? (
        <PGridRows
            currency={currency}
            data={filteredData}
        />
    ) : (
        <PGridRows
            currency={currency}
            data={filteredData}
        />
    );

    return (
        <Grid columns='equal' celled='internally' singleLine>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h1' style={{margin: '0px', padding: '0px'}}>{pilotData.pilot}</Header>
                </Grid.Column>
                <Grid.Column>
                    <Form.Dropdown
                        fluid
                        selection
                        options={currencyOptions}
                        value={currency}
                        onChange={handleCurrencyChange}
                        placeholder='Select currency...'
                    />
                </Grid.Column>
            </Grid.Row>
            {pGridRows}
        </Grid>
    );
}

export default Pilot;
