// React
import React from 'react';

// Styling and Semantics
import { Dimmer, Loader } from 'semantic-ui-react';
import * as Styled from './styles';

/**
 *  SiteLoading Component
 */
const SiteLoading = () => {
    return (
        <Styled.SoloSegment>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
        </Styled.SoloSegment>
    );
}

export default SiteLoading;
