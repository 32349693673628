// React
import React, { useState } from 'react';

// Libraries
import { get } from 'lodash';
import { toast } from 'react-toastify';
import API from '../../../lib/api';

// Styling and Semantics
import {
    Button,
    Container,
} from 'semantic-ui-react'

/**
 *  Contract/Management Component
 */
const Management = ( props ) => {
    const { corp, contractData, setContractData, authUser } = props;

    // State hooks
    const [formReady, setFormReady] = useState(true);

    const handlePayClick = () => {
        if (formReady) {
            setFormReady(false);
            API.getManageContractPay(
                corp, get(authUser, 'token'), get(contractData, 'id'),
                (contract) => toast.success('Contract approved successfully.', {
                    onOpen: () => {
                        setContractData(contract);
                    }
                }),
                (error) => toast.error(`${error}`, {
                    onClose: () => setFormReady(true),
                }),
            );
        }
    }

    const handleRejectClick = () => {
        if (formReady) {
            setFormReady(false);
            API.getManageContractReject(
                corp, get(authUser, 'token'), get(contractData, 'id'),
                (contract) => toast.success('Contract rejected successfully.', {
                    onOpen: () => {
                        setContractData(contract);
                    }
                }),
                (error) => toast.error(`${error}`, {
                    onClose: () => setFormReady(true),
                }),
            );
        }
    }

    return (
        <Container textAlign='center'>
            <Button.Group fluid>
                <Button type='submit' color='green' size='large' onClick={() => handlePayClick()}>
                    Approve &amp; Pay
                </Button>
                <Button.Or size='large' style={{marginTop: '3px'}} />
                <Button type='submit' color='red' size='large' onClick={() => handleRejectClick()}>
                    Reject
                </Button>
            </Button.Group>
        </Container>
    );
}

export default Management;
