// React
import React from 'react';

// Libraries
import { get, isEmpty, map, sortBy } from 'lodash';

// Styling and Semantics
import {
    Form,
    Header,
    Message,
    Table,
} from 'semantic-ui-react'

/**
 *  Deliver/Details Component
 */
const Details = ( props ) => {
    const { formData, formReady, bases, currencies, setFormField, validateForm } = props;

    const baseOptions = sortBy(map(bases, (base) => {
        let baseLabel = `[${base.star}]`;
        if (!isEmpty(base.description)) {
            baseLabel += ` - ${base.description}`;
        }
        return {
            key: base.id,
            text: baseLabel,
            value: base.star,
        };
    }), ['text']);

    const currencyOptions = sortBy(map(currencies, (coin) => {
        let coinLabel = `${coin.name}`;
        if (!isEmpty(coin.description)) {
            coinLabel += ` - ${coin.description}`;
        }
        return {
            key: coin.id,
            text: coinLabel,
            value: coin.name,
        };
    }), ['text']);

    const handleCurrencyChange = (e, data) => setFormField('currency', data.value, true);
    const handleStarChange = (e, data) => setFormField('star', data.value, true);

    const handleSubmitForApproval = (e, data) => {
        validateForm();
    }

    return (
        <Form>
            <Header as='h1'>Location / Currency</Header>
            <Table celled compact definition size='large'>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Starbase</Table.Cell>
                        <Table.Cell>
                            <Form.Dropdown
                                fluid
                                selection
                                options={baseOptions}
                                value={get(formData, 'star')}
                                onChange={handleStarChange}
                                placeholder='Select starbase...'
                                error={get(formData, 'errors.star') ? { content: get(formData, 'errors.star'), pointing: 'above' } : false}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Currency</Table.Cell>
                        <Table.Cell>
                            <Form.Dropdown
                                fluid
                                selection
                                options={currencyOptions}
                                value={get(formData, 'currency')}
                                onChange={handleCurrencyChange}
                                placeholder='Select currency...'
                                error={get(formData, 'errors.currency') ? { content: get(formData, 'errors.currency'), pointing: 'above' } : false}
                            />
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan={2}>
                            <Form.Button
                                loading={!formReady}
                                fluid
                                primary
                                className='button-submit'
                                size='massive'
                                onClick={handleSubmitForApproval}
                            >Update Prices</Form.Button>
                            {get(formData, 'errors.message') ? (
                                <Message
                                    error
                                    header='Missing or Invalid Entry'
                                    content={get(formData, 'errors.message')}
                                />
                            ) : ''}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Form>
    );
}

export default Details;
