// React
import React from 'react';
import { useHistory } from 'react-router-dom';

// Libraries
import { forEach, isEmpty, slice } from 'lodash';

// Styling and Semantics
import {
    Breadcrumb,
    Container,
    Menu,
} from 'semantic-ui-react';

/**
 *  Breadcrumbs Component
 */
const Breadcrumbs = (props) => {
    // Property Descructure
    const { config: { crumbs, setCrumbs } } = props;

    // State Hooks
    const history = useHistory();

    // Handler for clicking on a breadcrumb which includes a link.
    const handleClick = (key) => {
        // Grab the crumb that was clicked.
        const crumb = crumbs[key];
        // Remove all the crumbs after the one clicked.
        setCrumbs(slice(crumbs, 0, key+1));
        // Send us to the page.
        history.push(crumb.to);
    };

    const crumbSections = [];
    if (!isEmpty(crumbs)) {
        forEach(crumbs, (crumb, key) => {
            if (!isEmpty(crumb.to)) {
                crumbSections.push((
                    <Breadcrumb.Section key={key} link onClick={() => handleClick(key)} style={{color: 'darkgray'}}>{crumb.text}</Breadcrumb.Section>
                ));
            } else {
                crumbSections.push((
                    <Breadcrumb.Section key={key} style={{color: 'white'}}>{crumb.text}</Breadcrumb.Section>
                ));
            }
            crumbSections.push((
                <Breadcrumb.Divider key={`${key}x`} icon='angle double right' color='grey' />
            ));
        });
        crumbSections.pop();
    }

    return (
        <Menu className='breadcrumb-wrapper' fixed='top'>
            <Container style={{paddingLeft: '12px', paddingTop: '10px'}}>
                <Breadcrumb size='large'>
                    {crumbSections}
                </Breadcrumb>
            </Container>
        </Menu>
    );
}

export default Breadcrumbs;
