// React
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Context
import { useAuth } from './context/auth';

/**
 *  PrivateRoute Component
 */
function PrivateRoute({ render, ...rest }) {
    const isAuthenticated = useAuth();

    return (
        <Route
            {...rest}
            render={props =>
                isAuthenticated ? render(props) : <Redirect to='/login' />
            }
        />
    );
}

export default PrivateRoute;
