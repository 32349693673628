// External Libraries
import styled from 'styled-components/macro';

// Semantics
import {
    Image
} from 'semantic-ui-react';

export const PaddedImage = styled(Image)`
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 15px;
    width: 20%;
`;
