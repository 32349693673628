// React
import React from 'react';

// Libraries
import { get } from 'lodash';

// Styling and Semantics
import {
    Form,
    Header,
    Table,
} from 'semantic-ui-react'
import './Details.scss';

/**
 *  Order/Details Component
 */
const Details = ( props ) => {
    const { orderData } = props;
    return (
        <Form>
            <Header as='h1'>Corp/Alliance Store Purchase</Header>
            <Table celled compact definition size='large'>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Pilot Name</Table.Cell>
                        <Table.Cell className='pilot-name'>
                            {get(orderData.pilot, 'pilot')}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Starbase</Table.Cell>
                        <Table.Cell>
                            {get(orderData, 'star')}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Approved By</Table.Cell>
                        <Table.Cell>
                            {get(orderData, 'approver')}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Form>
    );
}

export default Details;
