// React
import React, { useEffect, useState } from 'react';

// Libraries
import { find, get, isEmpty, isNull, map, orderBy, sortBy, escapeRegExp } from 'lodash';
import { toast } from 'react-toastify';

import API from '../../lib/api';

// Styling and Semantics
import {
    Form,
    Grid,
    Header,
    Input,
} from 'semantic-ui-react';
import './Dashboard.scss';

// Components
import DTable from './DTable';
import DTableMobile from './DTableMobile';

/**
 *  Dashboard Component
 */
const Dashboard = ( props ) => {
    // Property Descructure
    const { config: { corp, isMobile, setCrumbs } } = props;

    // State hooks
    const [balanceData, setBalanceData] = useState([]);
    const [currenciesData, setCurrenciesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [searchText, setSearchText] = useState('');

    // Effect hooks
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCrumbs([
            { text: 'Home', to: '/' },
            { text: 'Dashboard' }
        ]);
    }, [setCrumbs]);

    useEffect(() => {
        if (!isEmpty(corp)) {
            API.getCurrencies(
                corp,
                (currencies) => {
                    setCurrenciesData(currencies);
                    const defaultCurrency = find(currencies, {'is_default': 1});
                    if (!isEmpty(defaultCurrency)) {
                        setCurrency(defaultCurrency.name);
                    }
                },
                (error) => toast.error('Unknown error getting currencies.')
            );
            API.getBalances(
                corp,
                (balances) => setBalanceData(sortBalanceData(balances, 'pilot', 'ascending')),
                (error) => toast.error('Unknown error getting balances.')
            );
        }
    }, [corp]);

    useEffect(() => {
        const newBalances = [];
        const regEx = new RegExp(escapeRegExp(searchText), 'gi');
        balanceData.forEach((balance) => {
            if ((searchText.trim() === '' || balance.pilot.match(regEx))
                  && (balance.currency === currency)) {
                newBalances.push(balance);
            }
        });
        setFilteredData(newBalances);
    }, [balanceData, searchText, currency]);

    const currencyOptions = sortBy(map(currenciesData, (coin) => {
        let coinLabel = `[${coin.name}]`;
        if (!isEmpty(coin.description)) {
            coinLabel += ` - ${coin.description}`;
        }
        return {
            key: coin.id,
            text: coinLabel,
            value: coin.name,
        };
    }), ['text']);

    const sortBalanceData = (data, column, direction) => {
        return orderBy(data, (o) => {
            if (isNull(get(o, column))) {
                return direction === 'ascending' ? '2099-12-31' : '1999-12-31';
            }
            return get(o, column);
        }, [direction === 'ascending' ? 'asc' : 'desc']
        );
    };

    const handleCurrencyChange = (e, data) => setCurrency(data.value);
    const handleSearchChange = (event, data) => setSearchText(data.value);

    const dataTable = isMobile ? (
        <DTableMobile data={filteredData}/>
    ) : (
        <DTable data={filteredData} sortBalanceData={sortBalanceData} />
    );

    return (
        <Grid columns='equal' celled='internally' singleLine>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h1' style={{margin: '0px', padding: '0px'}}>Dashboard</Header>
                </Grid.Column>
                <Grid.Column>
                    <Form.Dropdown
                        fluid
                        selection
                        options={currencyOptions}
                        value={currency}
                        onChange={handleCurrencyChange}
                        placeholder='Select currency...'
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Input icon='search' iconPosition='left' placeholder='Search Pilot Name...' fluid onChange={handleSearchChange}/>
                    {dataTable}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default Dashboard;
