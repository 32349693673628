// React
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Libraries
import { clone, filter, find, get, isEmpty, isUndefined, map, set, trim, unset, isNull } from 'lodash';
import { toast } from 'react-toastify';
import API from '../../lib/api';

// Styling and Semantics
import {
    Grid,
} from 'semantic-ui-react'

// Components
import Details from './Details';
import Prices from './Prices';

/**
 *  Settings Component
 */
const Settings = ( props ) => {
    // Property Descructure
    const { config: { corp, isMobile, setCrumbs, authUser } } = props;

    // State hooks
    const history = useHistory();
    const [formReady, setFormReady] = useState(true);
    const [basesData, setBasesData] = useState([]);
    const [currenciesData, setCurrenciesData] = useState([]);
    const [itemsData, setItemsData] = useState([]);
    const [itemTypesData, setItemTypesData] = useState([]);
    const [exchangesData, setExchangesData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [formData, setFormData] = useState({});

    // Effect hooks
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCrumbs([
            { text: 'Home', to: '/' },
            { text: 'Settings' }
        ]);
    }, [setCrumbs]);

    useEffect(() => {
        if (!isEmpty(corp)) {
            API.getBases(
                corp,
                (bases) => setBasesData(bases),
                (error) => toast.error('Unknown error getting starbases.')
            );
            API.getCurrencies(
                corp,
                (currencies) => setCurrenciesData(currencies),
                (error) => toast.error('Unknown error getting currencies.')
            );
            API.getItemTypes(
                (types) => setItemTypesData(types),
                (error) => toast.error('Unknown error getting item types.')
            );
            API.getItems(
                (types) => setItemsData(types),
                (error) => toast.error('Unknown error getting item types.')
            );
            API.getExchanges(
                corp,
                (exchanges) => setExchangesData(exchanges),
                (error) => toast.error('Unknown error getting exchanges.')
            );
            API.getProducts(
                corp,
                (products) => setProductsData(products),
                (error) => toast.error('Unknown error getting products.')
            );
            setFormData({
                ticker: corp.ticker
            })
        }
    }, [corp, setCrumbs]);


    const sendUpdatePrices = (data) => {
        data.ticker = corp.ticker;
        data.token = get(authUser, 'token');
        API.updatePrices(corp, data,
            (prices) => toast.success('Prices have been updated.', {
                onOpen: () => setFormReady(false),
                onClose: () => history.push(`/deliver`),
            }),
            (error) => toast.error(`${error}`, {
                onOpen: () => setFormReady(false),
                onClose: () => setFormReady(true),
            }),
        );
    }

    const getDefaultPrices = (star, currency) => {
        const exchanges = filter(exchangesData, { star: star, currency: currency });
        const products = filter(productsData, { star: star, currency: currency });
        const prices = map(itemsData, (item) => {
            return {
                item_id: item.id,
                exchange: {
                    rate: get(find(exchanges, { item: { id: item.id } }), 'rate') || '',
                    gross: get(find(exchanges, { item: { id: item.id } }), 'gross') || '',
                },
                product: {
                    rate: get(find(products, { item: { id: item.id } }), 'rate') || '',
                    gross: get(find(products, { item: { id: item.id } }), 'gross') || '',
                }
            }
        });

        return prices;
    };

    // Form Field Setters
    const setFormField = (field, value, resetPrices = false) => {
        const f = clone(formData);
        if (isNull(value)) {
            unset(f, field);
        } else {
            set(f, field, value);
        }
        if (resetPrices) {
            const prices = getDefaultPrices(get(f, 'star'), get(f, 'currency'));
            set(f, 'prices', prices);
        }
        setFormData(f);
    }

    // Set the defaults if necessary
    const defaultBase = find(basesData, {'is_default': 1});
    const defaultCurrency = find(currenciesData, {'is_default': 1});

    if (isEmpty(get(formData, 'star')) && !isEmpty(defaultBase)) {
        setFormField('star', defaultBase.star);
    }
    if (isEmpty(get(formData, 'currency')) && !isEmpty(defaultCurrency)) {
        setFormField('currency', defaultCurrency.name);
    }
    if (!isEmpty(get(formData, 'star')) && !isEmpty(get(formData, 'currency')) && !isEmpty(exchangesData) && isUndefined(formData.prices)) {
        setFormField('prices', getDefaultPrices(get(formData, 'star'), get(formData, 'currency')));
    }

    // The form validator
    const validateForm = () => {
        setFormReady(false);

        const f = clone(formData);
        f.errors = {};

        if (isEmpty(trim(formData.star))) {
            f.errors.star = 'Please select a starbase.';
        }
        if (isEmpty(trim(formData.currency))) {
            f.errors.currency = 'Please select a currency.';
        }
        if (!isEmpty(f.errors)) {
            f.errors.message = 'Please correct any empty or invalid entries before submitting.';
        }

        // On successful validation
        if (isEmpty(f.errors)) {
            if (formReady) {
                unset(f, 'errors');
                sendUpdatePrices(f);
            }
        } else {
            setFormReady(true);
        }
        setFormData(f);
    }

    const detailComponent = (
        <Details
            isMobile={isMobile}
            formReady={formReady}
            formData={formData}
            bases={basesData}
            currencies={currenciesData}
            setFormField={setFormField}
            validateForm={validateForm}
        />
    );

    const pricesComponent = (
        <Prices
            isMobile={isMobile}
            formReady={formReady}
            formData={formData}
            exchanges={exchangesData}
            products={productsData}
            items={itemsData}
            itemTypes={itemTypesData}
            setFormField={setFormField}
        />
    );

    return (
        <Grid celled='internally' columns='equal' stackable>
            <Grid.Row>
                <Grid.Column>{detailComponent}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>{pricesComponent}</Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default Settings;
