// React
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// External Libraries
import { clone, get, isEmpty, set, trim, unset, isNull } from 'lodash';
import { toast } from 'react-toastify';

// Local Libraries
import API from '../../../lib/api';
import crypt from '../../../lib/crypt';

// Styling and Semantics
import {
    Button, Container, Form, Grid, Message, Modal, Segment
} from 'semantic-ui-react';
import * as Styled from './styles';

/**
 *  Login Component
 */
const Login = ( props ) => {
    // Property Descructure
    const { corp, localization, setAuthorized } = props;

    // State hooks
    const history = useHistory();
    const [formReady, setFormReady] = useState(true);
    const [formData, setFormData] = useState({});

    const sendAuthRequest = () => {
        const { pilot, password } = formData;

        API.getAuthorization(
            corp.ticker, trim(pilot), crypt.encrypt(password),
            (user) => {
                if (!isEmpty(user)) {
                    setAuthorized(user);
                    toast.success('Login Success', {
                        onOpen: () => setFormReady(false),
                        onClose: () => history.push('/'),
                    });
                } else {
                    toast.error('Authentication Failed', {
                        onOpen: () => setFormReady(false),
                        onClose: () => setFormReady(true),
                    });
                }
            },
            (error) => {
                console.error(error);
                toast.error('Authentication Request Failure', {
                    onOpen: () => setFormReady(false),
                    onClose: () => setFormReady(true),
                });
            }
        );
    };

    // Form Field Setter
    const setFormField = (field, value ) => {
        const f = clone(formData);
        if (isNull(value)) {
            unset(f, field);
        } else {
            set(f, field, value);
        }
        setFormData(f);
    };

    const handlePilotChange = (e, data) => {
        setFormField('errors.pilot', null);
        setFormField('pilot', data.value)
    };
    const handlePasswordChange = (e, data) => {
        setFormField('errors.password', null);
        setFormField('password', data.value);
    };

    const handleCancelClick = () => {
        history.push('/')
    };

    const getValidationErrors = () => {
        const errors = {};
        if (trim(formData.pilot).length < 4 || trim(formData.pilot).length > 32) {
            errors.pilot = 'Pilot name must be between 4 and 32 letters.';
        }
        if (trim(formData.password).length < 4 || trim(formData.password).length > 32) {
            errors.password = 'Password must be between 4 and 32 letters.';
        }
        return errors;
    };

    const handleSubmitClick = () => {
        if (formReady) {
            setFormReady(false);
            const errors = getValidationErrors();
            if (isEmpty(errors)) {
                sendAuthRequest();
            } else {
                const f = clone(formData);
                f.errors = errors;
                setFormData(f);
                setFormReady(true);
            }
        }
    }

    return (
        <Modal
            closeOnEscape={false}
            closeOnDimmerClick={false}
            size='tiny'
            open={true} >
            <Modal.Content>
                <Grid textAlign='center' verticalAlign='middle'>
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Container textAlign='center'>
                            <Styled.PaddedImage src={corp.logo} />
                        </Container>
                        <Form size='large' onSubmit={() => handleSubmitClick()}>
                            <Segment stacked>
                                <Form.Input
                                    fluid
                                    icon='user'
                                    iconPosition='left'
                                    name='pilot'
                                    placeholder={get(localization, 'login.form.pilot.placeholder')}
                                    onChange={handlePilotChange}
                                    value={get(formData, 'pilot')}
                                    error={get(formData, 'errors.pilot')
                                        ? { content: get(formData, 'errors.pilot'), pointing: 'above' }
                                        : false}
                                />
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder={get(localization, 'login.form.password.placeholder')}
                                    type='password'
                                    onChange={handlePasswordChange}
                                    value={get(formData, 'password')}
                                    error={get(formData, 'errors.password')
                                        ? { content: get(formData, 'errors.password'), pointing: 'above' }
                                        : false}
                                />
                                <Button.Group fluid>
                                    <Button type='button' color='grey' size='large' onClick={() => handleCancelClick()}>
                                        {get(localization, 'login.form.cancel')}
                                    </Button>
                                    <Button.Or size='large' style={{marginTop: '3px'}} />
                                    <Button type='submit' color='violet' size='large' >
                                        {get(localization, 'login.form.login')}
                                    </Button>
                                </Button.Group>
                            </Segment>
                        </Form>
                        <Message>
                            <a href='/'>{get(localization, 'login.forgot')}</a>
                        </Message>
                    </Grid.Column>
                </Grid>
            </Modal.Content>
        </Modal>
    );
}

export default Login;
