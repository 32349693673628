// React
import React from 'react';

// Libraries
import { get } from 'lodash';

// Styling and Semantics
import {
    Form,
    Header,
    Table,
} from 'semantic-ui-react'
import './Details.scss';

/**
 *  Contract/Details Component
 */
const Details = ( props ) => {
    const { contractData } = props;
    return (
        <Form>
            <Header as='h1'>Delivery Details</Header>
            <Table celled compact definition size='large'>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Pilot Name</Table.Cell>
                        <Table.Cell className='pilot-name'>
                            {get(contractData.pilot, 'pilot')}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Starbase</Table.Cell>
                        <Table.Cell>
                            {get(contractData, 'star')}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell className='label-cell'>Assigned To</Table.Cell>
                        <Table.Cell>
                            {get(contractData, 'approver')}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </Form>
    );
}

export default Details;
