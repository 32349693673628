// React
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Libraries
import { clone, includes, isEmpty, map } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

// Styling and Semantics
import { Table } from 'semantic-ui-react';

/**
 *  Dashboard/DTable Component
 */
const DTable = ( props ) => {
    // Property Descructure
    const { data, sortBalanceData } = props;

    // State hooks
    const history = useHistory();
    const [sortedData, setSortedData] = useState([]);
    const [sortColumn, setSortColumn] = useState('pilot');
    const [sortDirection, setSortDirection] = useState('ascending');

    // Effect hooks
    useEffect(() => {
        setSortedData(data);
    }, [data]);

    const handleSort = (clickedColumn) => () => {
        // Direction
        let _direction = clone(sortDirection);
        let newDirection = includes(['pilot', 'debits'], clickedColumn) ? 'ascending' : 'descending';
        if (sortColumn === clickedColumn) {
            newDirection = _direction === 'ascending' ? 'descending' : 'ascending';
        }
        _direction = newDirection;
        setSortDirection(_direction);

        // Column
        let _column = clone(sortColumn);
        _column = clickedColumn;
        setSortColumn(_column);

        // Data
        let _balanceData = clone(sortedData);
        _balanceData = sortBalanceData(_balanceData, clickedColumn, newDirection);
        setSortedData(_balanceData);
    }

    const clickPilotRow = (pilotId) => {
        history.push(`/pilot/${pilotId}`);
    }

    const classForNumber = (num) => {
        if (num > 0) {
            return 'balance-positive';
        } if (num < 0) {
            return 'balance-negative';
        } else {
            return 'balance-even';
        }
    }

    const rows = [];
    map(sortedData, (balance) => {
        rows.push((
            <Table.Row className='clickable-row' onClick={() => { clickPilotRow(balance.pilot_id)} }>
                <Table.Cell className='cell-balance left pilot-name'>
                    {balance.pilot}
                </Table.Cell>
                <Table.Cell className='cell-balance right'>
                    <div className={classForNumber(balance.credits)}>{numeral(balance.credits).format('0,0')}</div>
                </Table.Cell>
                <Table.Cell className='cell-balance right'>
                    <div className={classForNumber(balance.debits)}>{numeral(balance.debits).format('0,0')}</div>
                </Table.Cell>
                <Table.Cell className='cell-balance right'>
                    <div className={classForNumber(balance.balance)}>{numeral(balance.balance).format('0,0')}</div>
                    {balance.balance !== 0 ? balance.currency : ''}
                </Table.Cell>
                <Table.Cell className='cell-balance center'>
                    {!isEmpty(balance.last_contract_date) ? moment(balance.last_contract_date).format('ll') : '-'}
                </Table.Cell>
            </Table.Row>
        ));
    });

    return (
        <Table color='blue' compact selectable singleLine sortable striped size='large'>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell
                        className='centered'
                        sorted={sortColumn === 'pilot' ? sortDirection : null}
                        onClick={handleSort('pilot')}
                    >Pilot</Table.HeaderCell>
                    <Table.HeaderCell
                        className='centered'
                        sorted={sortColumn === 'credits' ? sortDirection : null}
                        onClick={handleSort('credits')}
                    >Credits Earned</Table.HeaderCell>
                    <Table.HeaderCell
                        className='centered'
                        sorted={sortColumn === 'debits' ? sortDirection : null}
                        onClick={handleSort('debits')}
                    >Debits Spent</Table.HeaderCell>
                    <Table.HeaderCell
                        className='centered'
                        sorted={sortColumn === 'balance' ? sortDirection : null}
                        onClick={handleSort('balance')}
                    >Balance</Table.HeaderCell>
                    <Table.HeaderCell
                        className='centered'
                        sorted={sortColumn === 'last_contract_date' ? sortDirection : null}
                        onClick={handleSort('last_contract_date')}
                    >Last Contract</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {rows}
            </Table.Body>
        </Table>
    );
}

export default DTable;
