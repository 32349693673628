// React
import React from 'react';
import { useHistory } from 'react-router-dom';

// Libraries
import { isEmpty, map } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

// Styling and Semantics
import { Label, Table } from 'semantic-ui-react';

/**
 *  Dashboard/DTableMobile Component
 */
const DTableMobile = ( props ) => {
    // Property Descructure
    const { data } = props;

    // State hooks
    const history = useHistory();

    const clickPilotRow = (pilotId) => {
        history.push(`/pilot/${pilotId}`);
    }

    const classForNumber = (num) => {
        if (num > 0) {
            return 'balance-positive';
        } if (num < 0) {
            return 'balance-negative';
        } else {
            return 'balance-even';
        }
    }

    const rows = [];
    map(data, (balance) => {
        rows.push((
            <Table.Row className='clickable-row' onClick={() => { clickPilotRow(balance.pilot_id)} }>
                <Table.Cell className='pilot-name'>
                    <Label className='label-field-wide' pointing='right'>Pilot</Label>
                    {balance.pilot}
                </Table.Cell>
                <Table.Cell >
                    <Label className='label-field-wide' pointing='right'>Credits</Label>
                    <div className={classForNumber(balance.credits)}>{numeral(balance.credits).format('0,0')}</div>
                </Table.Cell>
                <Table.Cell>
                    <Label className='label-field-wide' pointing='right'>Debits</Label>
                    <div className={classForNumber(balance.debits)}>{numeral(balance.debits).format('0,0')}</div>
                </Table.Cell>
                <Table.Cell>
                    <Label className='label-field-wide' pointing='right'>Balance</Label>
                    <div className={classForNumber(balance.balance)}>{numeral(balance.balance).format('0,0')}</div>
                    {balance.balance !== 0 ? balance.currency : ''}
                </Table.Cell>
                <Table.Cell>
                    <Label className='label-field-wide' pointing='right'>Last</Label>
                    {!isEmpty(balance.last_contract_date) ? moment(balance.last_contract_date).format('ll') : '-'}
                </Table.Cell>
            </Table.Row>
        ));
    });

    return (
        <Table color='blue' compact selectable singleLine sortable striped size='large'>
            <Table.Body>
                {rows}
            </Table.Body>
        </Table>
    );
}

export default DTableMobile;
