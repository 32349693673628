// React
import React from 'react';

// Styling and Semantics
import {
    Container,
    Image,
    Segment,
} from 'semantic-ui-react'

/**
 *  Footer Component
 */
const Footer = () => {
    return (
        <Segment inverted vertical>
            <Container textAlign='center'>
                <Image centered size='mini' src='/img/null-coin.png' />
            </Container>
        </Segment>
    );
}

export default Footer;
