// React
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Styling and Semantics
import {
    Container,
    Grid,
    Header,
    Icon,
} from 'semantic-ui-react'

// Components
import Login from './Login';
import Unavailable from './Unavailable';

/**
    <Grid.Column>
        <Container as={ Link } to='/page'>
            <Header as='h1' icon>
                <Icon name='book' size='massive' />
                Pilot Ledger
                <Header.Subheader>
                    Detailed transaction history of a pilot
                </Header.Subheader>
            </Header>
        </Container>
    </Grid.Column>

    <Grid.Column>
        <Container as={ Link } to='/page'>
            <Header as='h1' icon>
                <Icon name='settings' size='massive' />
                    Corp. Settings
                <Header.Subheader>
                    Manage corporation settings
                </Header.Subheader>
            </Header>
        </Container>
    </Grid.Column>
 */

/**
 *  Console Component
 */
const Console = ( props ) => {
    // Property Descructure
    const { action, config: { localization, corp, setAuthorized, setCrumbs } } = props;

    // Effect hooks
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCrumbs([
            { text: 'Home' },
        ]);
    }, [setCrumbs]);

    let authModal = '';
    switch (action) {
        case 'login':
            authModal = (<Login corp={corp} localization={localization} setAuthorized={setAuthorized} />);
            break

        case 'unavailable':
            authModal = (<Unavailable />);
            break;

        default :
    }
    return (
        <Container textAlign='center'>
            {authModal}
            <Grid celled='internally' columns='equal' stackable>
                <Grid.Row columns='equal'>
                    <Grid.Column>
                        <Container as={ Link } to='/deliver'>
                            <Header as='h1' icon >
                                <Icon name='shipping fast' size='massive' />
                                Deliver / Deposit
                                <Header.Subheader>
                                    Setup a delivery contract to the Corp/Alliance
                                </Header.Subheader>
                            </Header>
                        </Container>
                    </Grid.Column>
                    <Grid.Column>
                        <Container as={ Link } to='/dashboard'>
                            <Header as='h1' icon>
                                <Icon name='dashboard' size='massive' />
                                Dashboard
                                <Header.Subheader>
                                    Summarized balance sheet for all pilots
                                </Header.Subheader>
                            </Header>
                        </Container>
                    </Grid.Column>
                    <Grid.Column>
                        <Container as={ Link } to='/shop'>
                            <Header as='h1' icon>
                                <Icon name='shopping cart' size='massive' />
                                Store
                                <Header.Subheader>
                                    Go shopping and spend your Coin / ISK
                                </Header.Subheader>
                            </Header>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Container as={ Link } to='/manage'>
                            <Header as='h1' icon>
                                <Icon name='dolly flatbed' size='massive' />
                                Manage Inventory
                                <Header.Subheader>
                                    Approve contracts &amp; manage inventory
                                </Header.Subheader>
                            </Header>
                        </Container>
                    </Grid.Column>
                    <Grid.Column>
                        <Container as={ Link } to='/settings'>
                            <Header as='h1' icon>
                                <Icon name='settings' size='massive' />
                                    Corp. Settings
                                <Header.Subheader>
                                    Manage corporation settings
                                </Header.Subheader>
                            </Header>
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default Console;
