// React
import React, { useEffect, useState } from 'react';

// Libraries
import { isEmpty, get } from 'lodash';
import { toast } from 'react-toastify';
import API from '../../lib/api';

// Styling and Semantics
import {
    Grid,
} from 'semantic-ui-react'

// Components
import Contents from './Contents';
import Details from './Details';
import Management from './Management';
import Valuation from './Valuation';

/**
 *  Order Component
 */
const Order = ( props ) => {
    // Property Descructure
    const { config: { corp, isMobile, setCrumbs, authUser } } = props;
    const { id } = props.match.params;

    // State hooks
    const [orderData, setOrderData] = useState([]);

    // Effect hooks
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCrumbs([
            { text: 'Home', to: '/' },
            { text: 'Dashboard', to: '/dashboard' },
            { text: 'Pilot Ledger', to: '/pilot/'+get(orderData.pilot, 'id') },
            { text: 'Order' }
        ]);
    }, [setCrumbs, orderData]);

    useEffect(() => {
        if (!isEmpty(corp)) {
            API.getOrder(
                corp, id,
                (order) => setOrderData(order),
                (error) => toast.error('Unknown error getting starbases.')
            );
        }
    }, [corp, id]);

    const managementRow = get(authUser, 'token') && get(orderData, 'resolved') === 0 ? (
        <Grid.Row>
            <Grid.Column>
                <Management
                    isMobile={isMobile}
                    corp={corp}
                    orderData={orderData}
                    setOrderData={setOrderData}
                    authUser={authUser}
                />
            </Grid.Column>
        </Grid.Row>
    ) : ( '' );

    switch (isMobile) {
        case true: return (
            <Grid celled='internally' columns='equal' stackable>
                <Grid.Row>
                    <Grid.Column>
                        <Details
                            isMobile={isMobile}
                            orderData={orderData}
                        />
                    </Grid.Column>
                </Grid.Row>
                {managementRow}
                <Grid.Row>
                    <Grid.Column>
                        <Contents
                            isMobile={isMobile}
                            orderData={orderData}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Valuation
                            isMobile={isMobile}
                            orderData={orderData}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );

        default: return (
            <Grid celled='internally' columns='equal' stackable>
                <Grid.Row>
                    <Grid.Column>
                        <Details
                            isMobile={isMobile}
                            orderData={orderData}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <Valuation
                            isMobile={isMobile}
                            orderData={orderData}
                        />
                    </Grid.Column>
                </Grid.Row>
                {managementRow}
                <Grid.Row>
                    <Grid.Column>
                        <Contents
                            isMobile={isMobile}
                            orderData={orderData}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default Order;
