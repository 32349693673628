// External Libraries
import Cookies from 'js-cookie';
import { isUndefined } from 'lodash';

const getAuthUser = () => {
    const user = Cookies.get('user');
    if (isUndefined(user)) {
        return false;
    }
    return JSON.parse(user);
};
const setAuthUser = (user) => {
    Cookies.set('user', JSON.stringify(user), { expires: 7 });
};

const getCorporation = () => {
    const user = Cookies.get('corp');
    if (isUndefined(user)) {
        return false;
    }
    return JSON.parse(user);
};
const setCorporation = (corp) => {
    Cookies.set('corp', JSON.stringify(corp), { expires: 7 });
};

export default {
    getAuthUser,
    setAuthUser,
    getCorporation,
    setCorporation
};
