// React
import React, { useEffect, useState } from 'react';

// Libraries
import {
    find, get, isEmpty, map, sortBy
} from 'lodash';
import { toast } from 'react-toastify';

import API from '../../lib/api';

// Styling and Semantics
import {
    Form,
    Grid,
    Header,
} from 'semantic-ui-react';

// Components
import MGridRows from './MGridRows';

/**
 *  Pilot Component
 */
const Pilot = ( props ) => {
    // Property Descructure
    const { config: { corp, setCrumbs, authUser } } = props;

    // State hooks
    const [currency, setCurrency] = useState();
    const [currenciesData, setCurrenciesData] = useState([]);
    const [contractsData, setContractsData] = useState([]);
    const [filteredContractsData, setFilteredContractsData] = useState([]);
    const [ordersData, setOrdersData] = useState([]);
    const [filteredOrdersData, setFilteredOrdersData] = useState([]);

    // Effect hooks
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCrumbs([
            { text: 'Home', to: '/' },
            { text: 'Management' },
        ]);
    }, [setCrumbs]);

    useEffect(() => {
        if (!isEmpty(corp)) {
            API.getCurrencies(
                corp,
                (currencies) => {
                    setCurrenciesData(currencies);
                    const defaultCurrency = find(currencies, {'is_default': 1});
                    if (!isEmpty(defaultCurrency)) {
                        setCurrency(defaultCurrency.name);
                    }
                },
                (error) => toast.error('Unknown error getting currencies.')
            );
            API.getManagedContracts(
                corp, get(authUser, 'token'),
                (contracts) => setContractsData(contracts),
                (error) => toast.error('Unknown error getting contracts data.')
            );
            API.getManagedOrders(
                corp, get(authUser, 'token'),
                (orders) => setOrdersData(orders),
                (error) => toast.error('Unknown error getting orders data.')
            );
        }
    }, [corp, authUser]);

    useEffect(() => {
        const newOrders = [];
        const newContracts = [];
        contractsData.forEach((contract) => {
            if ((contract.currency === currency)) {
                newContracts.push(contract);
            }
        });
        ordersData.forEach((order) => {
            if ((order.currency === currency)) {
                newOrders.push(order);
            }
        });
        setFilteredContractsData(newContracts);
        setFilteredOrdersData(newOrders);
    }, [contractsData, ordersData, currency]);

    const currencyOptions = sortBy(map(currenciesData, (coin) => {
        let coinLabel = `[${coin.name}]`;
        if (!isEmpty(coin.description)) {
            coinLabel += ` - ${coin.description}`;
        }
        return {
            key: coin.id,
            text: coinLabel,
            value: coin.name,
        };
    }), ['text']);

    const handleCurrencyChange = (e, data) => setCurrency(data.value);

    const contractRows = contractsData.length > 0 ? (
        <MGridRows
            currency={currency}
            data={filteredContractsData}
            label={'Contract'}
            pathing={'contract'}
        />
    ) : (
        <Header as='h2'>There are no open contracts awaiting approval for {currency}.</Header>
    );

    const orderRows = ordersData.length > 0 ? (
        <MGridRows
            currency={currency}
            data={filteredOrdersData}
            label={'Order'}
            pathing={'order'}
        />
    ) : (
        <Header as='h2'>There are no store orders awaiting approval for {currency}.</Header>
    );

    return (
        <Grid columns='equal' celled='internally' singleLine>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h1' style={{margin: '0px', padding: '0px'}}>Contract Management</Header>
                </Grid.Column>
                <Grid.Column>
                    <Form.Dropdown
                        fluid
                        selection
                        options={currencyOptions}
                        value={currency}
                        onChange={handleCurrencyChange}
                        placeholder='Select currency...'
                    />
                </Grid.Column>
            </Grid.Row>
            {orderRows}
            {contractRows}
        </Grid>
    );
}

export default Pilot;
