// Local Libraries
import API from './api-endpoints';

const apiGet = async (url, onSuccess, onFailure) => {
    return fetch(url, {
        method: 'GET'
    })
        .then(async (response) => {
            const data = await response.json();
            if (response.status !== 200) {
                throw new Error(data.message);
            }
            return data;
        })
        .then((data) => onSuccess(data))
        .catch((error) => onFailure(error));
};

const apiPost = async (url, body, onSuccess, onFailure) => {
    return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:    JSON.stringify(body),
    })
        .then(async (response) => {
            const data = await response.json();
            if (response.status !== 200) {
                throw new Error(data.message);
            }
            return data;
        })
        .then((contract) => onSuccess(contract))
        .catch((error) => onFailure(error));
};

const getApprovers = (corp, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.APPROVERS(corp)}`, onSuccess, onFailure
);

const getAuthorization = (ticker, pilot, password, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.AUTH()}`, { ticker, pilot, password }, onSuccess, onFailure
);

const getBases = (corp, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.BASES(corp)}`, onSuccess, onFailure
);

const getBalances = (corp, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.BALANCES(corp)}`, onSuccess, onFailure
);

const getContract = (corp, id, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.CONTRACTS(corp)}/${id}`, onSuccess, onFailure
);

const getCorporation = (hostname, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.TICKER()}`, { hostname }, onSuccess, onFailure
);

const getCurrencies = (corp, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.CURRENCIES(corp)}`, onSuccess, onFailure
);

const getExchanges = (corp, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.EXCHANGES(corp)}`, onSuccess, onFailure
);

const getItems = (onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.ITEMS()}`, onSuccess, onFailure
);

const getItemTypes = (onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.ITEM_TYPES()}`, onSuccess, onFailure
);

const getLedger = (corp, id, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.LEDGER(corp)}/${id}`, onSuccess, onFailure
);

const getLocalizations = (country, language, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.LOCALIZATIONS(country, language)}`, onSuccess, onFailure
);

const getManagedContracts = (corp, token, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.MANAGED_CONTRACTS(corp)}`, { token }, onSuccess, onFailure
);

const getManageContractReject = (corp, token, id, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.MANAGE_CONTRACT_REJECT(corp)}`, { token, id }, onSuccess, onFailure
);

const getManageContractPay = (corp, token, id, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.MANAGE_CONTRACT_PAY(corp)}`, { token, id }, onSuccess, onFailure
);

const getManagedOrders = (corp, token, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.MANAGED_ORDERS(corp)}`, { token }, onSuccess, onFailure
);

const getManageOrderReject = (corp, token, id, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.MANAGE_ORDER_REJECT(corp)}`, { token, id }, onSuccess, onFailure
);

const getManageOrderPay = (corp, token, id, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.MANAGE_ORDER_PAY(corp)}`, { token, id }, onSuccess, onFailure
);

const getOrder = (corp, id, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.ORDERS(corp)}/${id}`, onSuccess, onFailure
);

const getPilot = (corp, id, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.PILOT(corp)}/${id}`, onSuccess, onFailure
);

const getProducts = (corp, onSuccess, onFailure) => apiGet(
    `${API.HOSTNAME}${API.PRODUCTS(corp)}`, onSuccess, onFailure
);

const createContract = (corp, contract, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.CONTRACTS(corp)}`, contract, onSuccess, onFailure
);

const createOrder = (corp, order, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.ORDERS(corp)}`, order, onSuccess, onFailure
);

const updatePrices = (corp, priceData, onSuccess, onFailure) => apiPost(
    `${API.HOSTNAME}${API.PRICES(corp)}`, priceData, onSuccess, onFailure
);

export default {
    getAuthorization,
    getApprovers,
    getBases,
    getBalances,
    getContract,
    getCorporation,
    getCurrencies,
    getExchanges,
    getItems,
    getItemTypes,
    getLedger,
    getLocalizations,
    getManagedContracts,
    getManageContractReject,
    getManageContractPay,
    getManagedOrders,
    getManageOrderReject,
    getManageOrderPay,
    getOrder,
    getPilot,
    getProducts,
    createContract,
    createOrder,
    updatePrices,
}
