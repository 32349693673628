// React
import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

// External Libraries
import { get } from 'lodash';

// Local Libraries
import API from './lib/api';
import Cookies from './lib/cookies';

// Styling and Semantics
import { Container } from 'semantic-ui-react';
import './App.scss';

// Toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Context
import { AuthContext } from './context/auth';

// Base Components
import PrivateRoute from './PrivateRoute';
import Breadcrumbs from './components/Breadcrumbs';
import Navigation from './components/Navigation';
import SiteLoading from './components/SiteLoading';
import Footer from './components/Footer';

// Routed Components
import Console from './components/Console';
import Contract from './components/Contract';
import Dashboard from './components/Dashboard';
import Deliver from './components/Deliver';
import Manage from './components/Manage';
import Order from './components/Order';
import Pilot from './components/Pilot';
import Settings from './components/Settings';
import Shop from './components/Shop';

const country = 'us';
const language = 'en';

/**
 *  App Component
 */
const App = () => {
    // State hooks
    const [authUser, setAuthUser] = useState();
    const [corporation, setCorporation] = useState();
    const [isMobile, setIsMobile] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [crumbs, setCrumbs] = useState([]);
    const [localization, setLocalization] = useState([]);

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth < 500);
    };

    // Effect hooks
    useEffect(() => {
        // Set the isMobile flag for components to leverage when rendering.
        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange();

        // Check for a cookie that defines our corporation, and fetch the corp data otherwise.
        const corpFromCookie = Cookies.getCorporation();
        if (corpFromCookie) {
            setCorporation(corpFromCookie);
        } else {
            API.getCorporation(
                window.location.host,
                (corp) => {
                    setCorporation(corp);
                    Cookies.setCorporation(corp);
                },
                (error) => {
                    toast.error('Corporation Request Error');
                    console.error(error);
                }
            );
        }

        // Fetch the localizations
        API.getLocalizations(
            country, language,
            (response) => {
                setLocalization(response);
                setIsReady(true);
            },
            (error) => {
                toast.error('Localization Request Error');
                console.error(error);
            }
        );

        // Check for a cookie that defines our user.
        const userFromCookie = Cookies.getAuthUser();
        if (userFromCookie) {
            setAuthUser(userFromCookie);
        }
    }, []);

    const setAuthorized = (user) => {
        Cookies.setAuthUser(user);
        setAuthUser(user);
    };

    const config = {
        authUser,
        corp: corporation,
        crumbs,
        isMobile,
        localization,
        setAuthorized,
        setCrumbs,
    };

    const isAuthenticated = get(authUser, 'pilot') || false;

    const routing = isReady ? (
        <Router>
            <Navigation config={config} />
            <Breadcrumbs config={config} />
            <Container className='content-wrapper'>
                <Switch>
                    <Route exact path='/login' render={(props) =>
                        <Console {...props} config={config} action='login' />} />

                    <Route exact path='/contract/:id' render={(props) =>
                        <Contract {...props} config={config} />} />
                    <Route exact path='/order/:id' render={(props) =>
                        <Order {...props} config={config} />} />

                    <Route exact path='/dashboard' render={(props) =>
                        <Dashboard {...props} config={config} />} />
                    <Route exact path='/deliver' render={(props) =>
                        <Deliver {...props} config={config} />} />
                    <Route exact path='/pilot/:id' render={(props) =>
                        <Pilot {...props} config={config} />} />
                    <Route exact path='/shop' render={(props) =>
                        <Shop {...props} config={config} />} />

                    <PrivateRoute exact path='/manage' render={(props) =>
                        <Manage {...props} config={config} />} />
                    <PrivateRoute  exact path='/settings' render={(props) =>
                        <Settings {...props} config={config} />} />

                    <Route path='/' render={(props) =>
                        <Console {...props} config={config} />} />
                </Switch>
            </Container>
            <Footer />
        </Router>
    ) : (
        <SiteLoading />
    );

    return (
        <div className='body-wrapper'>
            <AuthContext.Provider value={isAuthenticated}>
                {routing}
            </AuthContext.Provider>
            <ToastContainer
                position='bottom-center'
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
            />
        </div>
    );
}

export default (App);
