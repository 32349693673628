// React
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Libraries
import { clone, find, get, isEmpty, set, trim, unset, isNull } from 'lodash';
import { toast } from 'react-toastify';
import API from '../../lib/api';

// Styling and Semantics
import {
    Grid,
} from 'semantic-ui-react'

// Components
import Contents from './Contents';
import Details from './Details';
import Valuation from './Valuation';

/**
 *  Deliver Component
 */
const Deliver = ( props ) => {
    // Property Descructure
    const { config: { corp, isMobile, setCrumbs } } = props;

    // State hooks
    const history = useHistory();
    const [formReady, setFormReady] = useState(true);
    const [approversData, setApproversData] = useState([]);
    const [basesData, setBasesData] = useState([]);
    const [currenciesData, setCurrenciesData] = useState([]);
    const [itemTypesData, setItemTypesData] = useState([]);
    const [exchangesData, setExchangesData] = useState([]);
    const [formData, setFormData] = useState({});

    // Effect hooks
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        setCrumbs([
            { text: 'Home', to: '/' },
            { text: 'Deliver / Deposit' }
        ]);
    }, [setCrumbs]);

    useEffect(() => {
        if (!isEmpty(corp)) {
            API.getApprovers(
                corp,
                (pilots) => setApproversData(pilots),
                (error) => toast.error('Unknown error getting approver pilots.')
            );
            API.getBases(
                corp,
                (bases) => setBasesData(bases),
                (error) => toast.error('Unknown error getting starbases.')
            );
            API.getCurrencies(
                corp,
                (currencies) => setCurrenciesData(currencies),
                (error) => toast.error('Unknown error getting currencies.')
            );
            API.getExchanges(
                corp,
                (exchanges) => setExchangesData(exchanges),
                (error) => toast.error('Unknown error getting exchanges.')
            );
            API.getItemTypes(
                (types) => setItemTypesData(types),
                (error) => toast.error('Unknown error getting item types.')
            );
            setFormData({
                ticker: corp.ticker
            })
        }
    }, [corp, setCrumbs]);

    const sendCreateContract = (data) => {
        API.createContract(corp, data,
            (contract) => toast.success('Your contract has been submitted for review.', {
                onOpen: () => setFormReady(false),
                onClose: () => history.push(`/contract/${contract.id}`),
            }),
            (error) => toast.error(`${error}`, {
                onOpen: () => setFormReady(false),
                onClose: () => setFormReady(true),
            }),
        );
    }

    // Form Field Setters
    const setFormField = (field, value, resetDelivery = false) => {
        const f = clone(formData);
        if (resetDelivery) {
            set(f, 'deliverables', {});
        }
        if (isNull(value)) {
            unset(f, field);
        } else {
            set(f, field, value);
        }
        setFormData(f);
    }

    // Set the defaults if necessary
    const defaultBase = find(basesData, {'is_default': 1});
    if (isEmpty(get(formData, 'star')) && !isEmpty(defaultBase)) {
        setFormField('star', defaultBase.star);
    }
    const defaultCurrency = find(currenciesData, {'is_default': 1});
    if (isEmpty(get(formData, 'currency')) && !isEmpty(defaultCurrency)) {
        setFormField('currency', defaultCurrency.name);
    }

    // The form validator
    const validateForm = () => {
        setFormReady(false);

        const f = clone(formData);
        f.errors = {};

        if (trim(formData.pilot).length < 4 || trim(formData.pilot).length > 32) {
            f.errors.pilot = 'Pilot name must be between 4 and 32 letters.';
        }
        if (isEmpty(trim(formData.star))) {
            f.errors.star = 'Please select a starbase.';
        }
        if (isEmpty(trim(formData.currency))) {
            f.errors.currency = 'Please select a currency.';
        }
        if (isEmpty(trim(formData.approver))) {
            f.errors.approver = 'Please select who will recieve this contract.';
        }
        if (!isEmpty(f.errors)) {
            f.errors.message = 'Please correct any empty or invalid entries before submitting.';
        }
        if (isEmpty(f.deliverables)) {
            f.errors.message = 'You must include some items for delivery.';
        }

        // On successful validation
        if (isEmpty(f.errors)) {
            if (formReady) {
                unset(f, 'errors');
                sendCreateContract(f);
            }
        } else {
            setFormReady(true);
        }
        setFormData(f);
    }

    const detailComponent = (
        <Details
            isMobile={isMobile}
            formReady={formReady}
            formData={formData}
            approvers={approversData}
            bases={basesData}
            currencies={currenciesData}
            setFormField={setFormField}
        />
    );

    const contentComponent = (
        <Contents
            isMobile={isMobile}
            formReady={formReady}
            formData={formData}
            exchanges={exchangesData}
            itemTypes={itemTypesData}
            setFormField={setFormField}
        />
    );

    const valuationComponent = (
        <Valuation
            isMobile={isMobile}
            formReady={formReady}
            formData={formData}
            setFormField={setFormField}
            validateForm={validateForm}
        />
    );

    switch (isMobile) {
        case true: return (
            <Grid celled='internally' columns='equal' stackable>
                <Grid.Row>
                    <Grid.Column>{detailComponent}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>{contentComponent}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>{valuationComponent}</Grid.Column>
                </Grid.Row>
            </Grid>
        );

        default: return (
            <Grid celled='internally' columns='equal' stackable>
                <Grid.Row>
                    <Grid.Column>{detailComponent}</Grid.Column>
                    <Grid.Column>{valuationComponent}</Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>{contentComponent}</Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default Deliver;
