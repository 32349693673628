export default {
    HOSTNAME: '/api',

    AUTH: () => '/auth',
    TICKER: () => '/corporations/hosted',
    ITEMS: () => '/items',
    ITEM_TYPES: () => '/items/types',

    APPROVERS: (corp) => `/approvers/${corp.ticker}`,
    BASES: (corp) => `/bases/${corp.ticker}`,
    BALANCES: (corp) => `/balances/${corp.ticker}`,
    CONTRACTS: (corp) => `/contracts/${corp.ticker}`,
    CURRENCIES: (corp) => `/currencies/${corp.ticker}`,
    EXCHANGES: (corp) => `/exchanges/${corp.ticker}`,
    LEDGER: (corp) => `/ledger/${corp.ticker}`,
    LOCALIZATIONS: (country, language) => `/localizations/${country}/${language}`,
    MANAGE_CONTRACT_REJECT: (corp) => `/managed/contract/reject/${corp.ticker}`,
    MANAGE_CONTRACT_PAY: (corp) => `/managed/contract/pay/${corp.ticker}`,
    MANAGED_CONTRACTS: (corp) => `/managed/contracts/${corp.ticker}`,
    MANAGE_ORDER_REJECT: (corp) => `/managed/order/reject/${corp.ticker}`,
    MANAGE_ORDER_PAY: (corp) => `/managed/order/pay/${corp.ticker}`,
    MANAGED_ORDERS: (corp) => `/managed/orders/${corp.ticker}`,
    ORDERS: (corp) => `/orders/${corp.ticker}`,
    PILOT: (corp) => `/pilot/${corp.ticker}`,
    PRICES: (corp) => `/prices/${corp.ticker}`,
    PRODUCTS: (corp) => `/products/${corp.ticker}`,
};
