// External Libraries
import crypto from 'crypto';

const key = 'TralaPrivateKeyXTralaPrivateKeyX'; // 32 character private key
const unsalted = 'TralaInitVectorX'; // 16 character initilization vector

const decrypt = (encryptedText, salt = unsalted) => {
    const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), salt);
    let plainText = decipher.update(encryptedText);
    plainText = Buffer.concat([plainText, decipher.final()]);
    return plainText.toString();
}

const encrypt = (plainText, salt = unsalted) => {
    const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), salt);
    let encryptedText = cipher.update(plainText);
    encryptedText = Buffer.concat([encryptedText, cipher.final()]);
    return encryptedText.toString('hex');
}

export default {
    decrypt,
    encrypt,
}
