// React
import React from 'react';

// Libraries
import { filter, find, get, map, replace, set, trimStart  } from 'lodash';

// Styling and Semantics
import {
    Accordion,
    Button,
    Header,
    Icon,
    Image,
    Input,
    Label,
    Table,
} from 'semantic-ui-react'
import './Prices.scss';

/**
 *  Settings/Prices Component
 */
const Prices = ( props ) => {
    const { isMobile, itemTypes, items, formData, setFormField } = props;

    const rateLabel = isMobile ? (<Label className='label-field-wide2' pointing='right'>Price:</Label>) : '';
    const grossLabel = isMobile ? (<Label className='label-field-wide2' pointing='right'>Per Units:</Label>) : '';

    const resetPriceField = (itemId, field) => {
        const prices = get(formData, 'prices') || [];
        const thisPrice = find(prices, { item_id: itemId });
        set(thisPrice, field, {
            rate: '', gross: ''
        });
        setFormField('prices', prices);
    };

    const savePriceField = (itemId, field, data) => {
        const rate = trimStart(replace(data.value, /\D\./g, ''), '0');
        const rateValue = rate !== '' ? rate : null;

        const prices = get(formData, 'prices') || [];
        const thisPrice = find(prices, { item_id: itemId });

        if (thisPrice) {
            set(thisPrice, field, rateValue);
        } else {
            const newPrice = {
                item_id: itemId,
                exchange: { rate: '', gross: '' },
                product: { rate: '', gross: '' }
            };
            set(newPrice, field, rateValue);
            prices.push(newPrice);
        };
        setFormField('prices', prices);
    }

    // The header rows do not display for mobile.
    let headerRows = [];
    if (!isMobile) {
        headerRows = (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell colSpan='2' textAlign='center' style={{backgroundColor: '#ccffcc'}}>Incoming</Table.HeaderCell>
                    <Table.HeaderCell colSpan='2' textAlign='center' style={{backgroundColor: '#ffffcc'}}>Outgoing</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell textAlign='center'>Price</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Per Units</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Price</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Per Units</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    const itemTypePanels = map(itemTypes, (type) => {
        const typedItems = filter(items, { type: type.name });
        if (typedItems.length <= 0) {
            return null;
        }

        const pricingRows = typedItems.map((item) => {
            // Resolve the image for the item in this exchange.
            const imagePath = item.image ? `/img/icons/${item.image}` : '';
            const itemImage = imagePath ? (<Image className='item-image' src={imagePath} />) : '';

            // Get the form data for this item
            const prices = find(formData.prices, {item_id: item.id});

            // Pull the quantity and the label from the state.
            const exchangeRate = get(prices, 'exchange.rate') || '';
            const exchangeGross = get(prices, 'exchange.gross') || '';
            const productRate = get(prices, 'product.rate') || '';
            const productGross = get(prices, 'product.gross') || '';

            return (
                <Table.Row key={item.id+'r'}>
                    <Table.Cell key={item.id+'c1'} className='cell-item-label' >
                        {itemImage} {item.name}&nbsp;&nbsp;
                    </Table.Cell>
                    <Table.Cell key={item.id+'c2'} className='cell-item-data'>
                        {rateLabel}
                        <Input
                            className={isMobile ? 'input-setting-mobile' : 'input-setting'}
                            name={'exchange.rate-' + item.id}
                            maxLength='12'
                            onChange={(e, data) => savePriceField(item.id, 'exchange.rate', data)}
                            placeholder='Price...'
                            value={exchangeRate}
                        />
                    </Table.Cell>
                    <Table.Cell key={item.id+'c3'} className='cell-item-data'>
                        {grossLabel}
                        <Input
                            className={isMobile ? 'input-setting-mobile' : 'input-setting'}
                            name={'exchange.gross-' + item.id}
                            maxLength='12'
                            onChange={(e, data) => savePriceField(item.id, 'exchange.gross', data)}
                            placeholder='per Units...'
                            value={exchangeGross}
                        />
                        <Button icon style={{backgroundColor: 'white'}}>
                            <Icon color='red' name='delete' onClick={() => resetPriceField(item.id, 'exchange')}/>
                        </Button>
                    </Table.Cell>
                    <Table.Cell key={item.id+'c5'} className='cell-item-data'>
                        {rateLabel}
                        <Input
                            className={isMobile ? 'input-setting-mobile' : 'input-setting'}
                            name={'product.rate-' + item.id}
                            maxLength='12'
                            onChange={(e, data) => savePriceField(item.id, 'product.rate', data)}
                            placeholder='Price...'
                            value={productRate}
                        />
                    </Table.Cell>
                    <Table.Cell key={item.id+'c6'} className='cell-item-data'>
                        {grossLabel}
                        <Input
                            className={isMobile ? 'input-setting-mobile' : 'input-setting'}
                            name={'product.gross-' + item.id}
                            maxLength='12'
                            onChange={(e, data) => savePriceField(item.id, 'product.gross', data)}
                            placeholder='per Units...'
                            value={productGross}
                        />
                        <Button icon style={{backgroundColor: 'white'}}>
                            <Icon color='red' name='delete' onClick={() => resetPriceField(item.id, 'product')}/>
                        </Button>
                    </Table.Cell>
                </Table.Row>
            );
        });

        const typeTable = (
            <Table celled compact definition singleLine>
                {headerRows}
                <Table.Body>
                    {pricingRows}
                </Table.Body>
            </Table>
        )

        return {
            key: type.name,
            title: type.description,
            content: { content: typeTable }
        }
    });

    return (
        <>
            <Header as='h1'>Pricing Guide</Header>
            <Accordion panels={itemTypePanels} fluid styled />
        </>
    );
}

export default Prices;
