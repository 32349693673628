// React
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Libraries
import {
    capitalize, clone, filter, get, includes, isNull, map, orderBy, round
} from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

// Styling and Semantics
import {
    Grid,
    Header,
    Table
} from 'semantic-ui-react';

const approverHeaders = {
    approved: 'Approved By',
    pending: 'Assigned To',
    rejected: 'Rejected By',
}

const gridLabels = {
    approved: 'Approved Contracts',
    pending: 'Pending Contracts',
    rejected: 'Rejected Contracts',
}

const balanceLabels = {
    approved: 'Balance',
    pending: 'Estimate',
    rejected: 'Rejected',
}

const typeLabels = {
    contract: 'Contract',
    order: 'Store Purchase',
    voucher: 'Voucher',
}

/**
 *  Pilot/PGridRows Component
 */
const PGridRows = ( props ) => {
    // Property Descructure
    const { currency, data } = props;

    // State hooks
    const history = useHistory();
    const [sortedData, setSortedData] = useState([]);
    const [sortColumn, setSortColumn] = useState('updated_at');
    const [sortDirection, setSortDirection] = useState('ascending');

    // Effect hooks
    useEffect(() => {
        setSortedData(data);
    }, [data]);

    const clickContractRow = (type, id) => {
        if (id) {
            history.push(`/${type}/${id}`);
        }
    }

    const sortLedgerData = (data, column, direction) => {
        return orderBy(data, (o) => {
            if (isNull(get(o, column))) {
                return direction === 'ascending' ? '2099-12-31' : '1999-12-31';
            }
            return get(o, column);
        }, [direction === 'ascending' ? 'asc' : 'desc']
        );
    };

    const handleSort = (clickedColumn) => () => {
        // Direction
        let _direction = clone(sortDirection);
        let newDirection = includes(['pilot', 'debits'], clickedColumn) ? 'ascending' : 'descending';
        if (sortColumn === clickedColumn) {
            newDirection = _direction === 'ascending' ? 'descending' : 'ascending';
        }
        _direction = newDirection;
        setSortDirection(_direction);

        // Column
        let _column = clone(sortColumn);
        _column = clickedColumn;
        setSortColumn(_column);

        // Data
        let _ledgerData = clone(sortedData);
        _ledgerData = sortLedgerData(_ledgerData, clickedColumn, newDirection);
        setSortedData(_ledgerData);
    }

    const classForNumber = (num) => {
        if (num > 0) {
            return 'balance-positive';
        } if (num < 0) {
            return 'balance-negative';
        } else {
            return 'balance-even';
        }
    }

    const ledgerApproved = filter(sortedData, {resolved: 1, status: 'paid' });
    const ledgerPending = filter(sortedData, {resolved: 0});

    const valueApproved = map(ledgerApproved, (entry) => round(numeral(get(entry, 'valuation')).value(), 4));
    const totalApproved = valueApproved.reduce((a, b) => a + b, 0);
    const valuePending = map(ledgerPending, (entry) => round(numeral(get(entry, 'valuation')).value(), 4));
    const totalPending = valuePending.reduce((a, b) => a + b, 0);

    const rowsApproved = [];
    const rowsPending = [];
    const rowsRejected = [];
    map(sortedData, (entry) => {
        const cellClass = `cell-balance center contract-status-${entry.status}`;

        let rowClick = () => {};
        if (get(entry, 'contract_id') > 0) {
            rowClick = () => { clickContractRow('contract', get(entry, 'contract_id'))};
        }
        else if (get(entry, 'order_id') > 0) {
            rowClick = () => { clickContractRow('order', get(entry, 'order_id'))};
        }
        const thisRow = (
            <Table.Row className='clickable-row' onClick={rowClick}>
                <Table.Cell className='cell-balance left pilot-name'>
                    {typeLabels[entry.type]}
                </Table.Cell>
                <Table.Cell className={cellClass}>
                    {capitalize(entry.status)}
                </Table.Cell>
                <Table.Cell className='cell-balance center'>
                    {entry.star}
                </Table.Cell>
                <Table.Cell className='cell-balance right'>
                    <div className={classForNumber(entry.valuation)}>{numeral(entry.valuation).format('0,0[.][0000]')}</div>
                </Table.Cell>
                <Table.Cell className='cell-balance'>
                    {entry.currency}
                </Table.Cell>
                <Table.Cell className='cell-balance'>
                    {entry.approver}
                </Table.Cell>
                <Table.Cell className='cell-balance center'>
                    {moment(entry.updated_at).format('ll')}
                </Table.Cell>
            </Table.Row>
        );
        if (['paid', 'voucher'].includes(entry.status)) {
            rowsApproved.push(thisRow);
        } else if (['open', 'received'].includes(entry.status)) {
            rowsPending.push(thisRow);
        } else {
            rowsRejected.push(thisRow);
        }
    });

    const getHeader = (status) => (
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell
                    className='centered'
                    sorted={sortColumn === 'type' ? sortDirection : null}
                    onClick={handleSort('type')}
                >Type</Table.HeaderCell>
                <Table.HeaderCell
                    className='centered'
                    sorted={sortColumn === 'status' ? sortDirection : null}
                    onClick={handleSort('status')}
                >Status</Table.HeaderCell>
                <Table.HeaderCell
                    className='centered'
                    sorted={sortColumn === 'starbase' ? sortDirection : null}
                    onClick={handleSort('starbase')}
                >Star</Table.HeaderCell>
                <Table.HeaderCell
                    className='centered'
                    sorted={sortColumn === 'valuation' ? sortDirection : null}
                    onClick={handleSort('valuation')}
                >Valuation</Table.HeaderCell>
                <Table.HeaderCell
                    className='centered'
                    sorted={sortColumn === 'currency' ? sortDirection : null}
                    onClick={handleSort('currency')}
                >Currency</Table.HeaderCell>
                <Table.HeaderCell
                    className='centered'
                    sorted={sortColumn === 'approver' ? sortDirection : null}
                    onClick={handleSort('approver')}
                >{approverHeaders[status]}</Table.HeaderCell>
                <Table.HeaderCell
                    className='centered'
                    sorted={sortColumn === 'updated_at' ? sortDirection : null}
                    onClick={handleSort('updated_at')}
                >Date</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
    );

    const getGridRow = (status, rows, total = 0) => {
        if (rows.length <= 0) {
            return '';
        }

        const totalRender = total > 0 ? (
            <div style={{float: 'right'}}>
                {balanceLabels[status]}:
                <div className={classForNumber(total)} style={{paddingLeft: '8px'}} >
                    {numeral(total).format('0,0[.][0000]')}
                </div> {currency}
            </div>
        ) : '';

        return (
            <Grid.Row>
                <Grid.Column>
                    <Header as='h3' style={{paddingBottom: '15px'}}>
                        <div style={{float: 'left'}}>{gridLabels[status]} </div>
                        {totalRender}
                    </Header>
                    <Table color='blue' compact selectable singleLine sortable striped size='large'>
                        {getHeader('pending')}
                        <Table.Body>
                            {rows}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
        );
    };

    return (
        <>
            {getGridRow('pending', rowsPending, totalPending)}
            {getGridRow('approved', rowsApproved, totalApproved)}
            {getGridRow('rejected', rowsRejected)}
        </>
    );
}

export default PGridRows;
