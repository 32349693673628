// React
import React from 'react';

// Libraries
import { isEmpty } from 'lodash';
import numeral from 'numeral';

// Styling and Semantics
import {
    Header,
    Image,
    Label,
    Table,
} from 'semantic-ui-react'
import './Contents.scss';

/**
 *  Contract/Contents Component
 */
const Contents = ( props ) => {
    const { isMobile, contractData } = props;
    const { deliverables } = contractData;

    const giveLabel = isMobile ? (<Label className='label-field' pointing='right'>Give:</Label>) : '';
    const valueLabel = isMobile ? (<Label className='label-field' pointing='right'>Value:</Label>) : '';

    // The header rows do not display for mobile.
    let headerRows = [];
    if (!isMobile) {
        headerRows = (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell className='cell-header'>Item Quantity</Table.HeaderCell>
                    <Table.HeaderCell className='cell-header' colSpan={isMobile ? 1 : 2}>Exchange Rate</Table.HeaderCell>
                    <Table.HeaderCell className='cell-header'>Estimated Value</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    // The deliverables in this contract
    let deliverableRows = [];
    if (isEmpty(deliverables)) {
        return (
            <Header as='h3'>No deliverables found for this contract.</Header>
        );
    } else {
        deliverableRows = deliverables.map((delivery) => {
            // Resolve the image for the item in this exchange.
            const imagePath = delivery.item.image ? `/img/icons/${delivery.item.image}` : '';
            const itemImage = imagePath ? (<Image className='item-image' style={{paddingRight: '5px'}} src={imagePath} />) : '';

            // Calculate the conversion rate for the item in this exchange.
            const conversion = delivery.rate / delivery.gross;

            let rateCells = [];
            if (isMobile) {
                rateCells.push((
                    <Table.Cell key={delivery.id+'c3'} className='cell-data'>
                        <Label className='label-field' pointing='right'>Rate:</Label>
                        <div className='wrapper-rate-mobile'>{numeral(conversion).format('0,0[.][0000]')}</div>
                        <div className='wrapper-math-mobile'>
                            {numeral(delivery.rate).format('0,0[.][0000]')+' '}
                            {delivery.currency} / {delivery.gross} unit{delivery.gross === 1 ? '' : 's'}
                        </div>
                    </Table.Cell>
                ));
            } else {
                rateCells.push((
                    <Table.Cell key={delivery.id+'c3'} className='cell-data'>
                        <div className='wrapper-rate'>{numeral(conversion).format('0,0[.][0000]')}</div>
                    </Table.Cell>
                ));
                rateCells.push((
                    <Table.Cell key={delivery.id+'c4'} className='cell-data'>
                        <div className='wrapper-math'>
                            {numeral(delivery.rate).format('0,0[.][0000]')+' '}
                            {delivery.currency} / {delivery.gross} unit{delivery.gross === 1 ? '' : 's'}
                        </div>
                    </Table.Cell>
                ));
            }

            return (
                <Table.Row key={delivery.id+'r'}>
                    <Table.Cell key={delivery.id+'c1'} className='cell-item-label' >
                        {itemImage} {delivery.item.name}&nbsp;&nbsp;
                    </Table.Cell>
                    <Table.Cell key={delivery.id+'c2'} className='cell-item-data'>
                        {giveLabel}{numeral(delivery.quantity).format('0,0[.][0000]')}
                    </Table.Cell>
                    {rateCells}
                    <Table.Cell key={delivery.id+'c5'} className='cell-data'>
                        {valueLabel}
                        <div className={isMobile ? 'wrapper-value-mobile' : 'wrapper-value'}>
                            {numeral(delivery.valuation).format('0,0[.][0000]')}
                        </div>
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    return (
        <div>
            <Header as='h1'>Deliverables</Header>
            <Table className='table-contents' celled compact definition singleLine size='large' >
                {headerRows}
                <Table.Body>
                    {deliverableRows}
                </Table.Body>
            </Table>
        </div>
    );
}

export default Contents;
