// React
import React from 'react';

// Libraries
import { filter, get, isEmpty, map, replace, round  } from 'lodash';
import numeral from 'numeral';

// Styling and Semantics
import {
    Accordion,
    Header,
    Image,
    Input,
    Label,
    Table,
} from 'semantic-ui-react'
import './Cart.scss';

/**
 *  Shop/Cart Component
 */
const Cart = ( props ) => {
    const { isMobile, formData, products, itemTypes, setFormField } = props;

    const giveLabel = isMobile ? (<Label className='label-field' pointing='right'>Give:</Label>) : '';
    const valueLabel = isMobile ? (<Label className='label-field' pointing='right'>Value:</Label>) : '';

    const saveDeliverQuantity = (id, gross, rate, data) => {
        // Remove any non-numeric characters from the quantity.
        const cleanQuantity = replace(data.value, /\D/g, '');
        if (data.value === '') {
            setFormField(data.name, null);
        } else {
            // Use numeral to convert our text in to a numeric variable, which strips leading zeros.
            const quantity = numeral(cleanQuantity).value() > 0 ? numeral(cleanQuantity).value() : 0;

            // Use numeral to round the provided value to the nearest ten-thousandth.
            const valuation = round(numeral((cleanQuantity > 0 ? cleanQuantity : 0) * (rate / gross)).value(), 4);

            // Format the value for the screen, including the currency suffic.
            const label = valuation > 0 ? `${numeral(valuation).format('0,0[.][0000]')} ${formData.currency}` : '-';

            // Update the state with our new object, represending an product entry for the new order.
            setFormField(data.name, { product: id, quantity, net: valuation, label });
        }
    }

    // Filter the products based on the currently selected star and currency.
    const productsFiltered = filter(products, {
        star: formData.star,
        currency: formData.currency
    });

    // The header rows do not display for mobile.
    let headerRows = [];
    if (!isMobile) {
        headerRows = (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell textAlign='center'>Quantity</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center' colSpan={isMobile ? 1 : 2}>Pricing</Table.HeaderCell>
                    <Table.HeaderCell textAlign='center'>Gross Total</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        );
    }

    const itemTypePanels = map(itemTypes, (type) => {
        const typedProducts = filter(productsFiltered, { item: { item_type: { name: type.name } } });
        if (typedProducts.length <= 0) {
            return null;
        }

        const productRows = typedProducts.map((product) => {
            // Resolve the image for the item in this product.
            const imagePath = product.item.image ? `/img/icons/${product.item.image}` : '';
            const itemImage = imagePath ? (<Image className='item-image' src={imagePath} />) : '';

            // Calculate the conversion rate for the item in this product.
            const conversion = product.rate / product.gross;

            // Pull the quantity and the label from the state.
            const quantity = get(formData, `deliverables.ex-${product.id}.quantity`) || '';
            const label = !isEmpty(get(formData, `deliverables.ex-${product.id}.label`))
                ? get(formData, `deliverables.ex-${product.id}.label`)
                : '-';

            let rateCells = [];
            if (isMobile) {
                rateCells.push((
                    <Table.Cell key={product.id+'c3'} className='cell-data'>
                        <Label className='label-field' pointing='right'>Rate:</Label>
                        <div className='wrapper-rate-mobile'>
                            {numeral(conversion).format('0,0[.][0000]')} {product.currency}
                        </div>
                        <div className='wrapper-math-mobile'>
                            {numeral(product.rate).format('0,0[.][0000]')+' '}
                            / {product.gross} unit{product.gross === 1 ? '' : 's'}
                        </div>
                    </Table.Cell>
                ));
            } else {
                rateCells.push((
                    <Table.Cell key={product.id+'c3'} className='cell-data'>
                        <div className='wrapper-rate'>
                            {numeral(conversion).format('0,0[.][0000]')} {product.currency}
                        </div>
                    </Table.Cell>
                ));
                rateCells.push((
                    <Table.Cell key={product.id+'c4'} className='cell-data'>
                        <div className='wrapper-math'>
                            {numeral(product.rate).format('0,0[.][0000]')+' '}
                            / {product.gross} unit{product.gross === 1 ? '' : 's'}
                        </div>
                    </Table.Cell>
                ));
            }

            return (
                <Table.Row key={product.id+'r'}>
                    <Table.Cell key={product.id+'c1'} className='cell-item-label' >
                        {itemImage} {product.item.name}&nbsp;&nbsp;
                    </Table.Cell>
                    <Table.Cell key={product.id+'c2'} className='cell-item-data'>
                        {giveLabel}
                        <Input
                            className={isMobile ? 'input-quantity-mobile' : 'input-quantity'}
                            name={'deliverables.ex-' + product.id}
                            maxLength='12'
                            onChange={(e, data) => saveDeliverQuantity(product.id, product.gross, product.rate, data)}
                            placeholder='Quantity...'
                            value={quantity}
                        />
                    </Table.Cell>
                    {rateCells}
                    <Table.Cell key={product.id+'c5'} className='cell-data'>
                        {valueLabel}
                        <div className={isMobile ? 'wrapper-value-mobile' : 'wrapper-value'}>{label}</div>
                    </Table.Cell>
                </Table.Row>
            );
        });

        const typeTable = (
            <Table celled compact definition singleLine size='large'>
                {headerRows}
                <Table.Body>
                    {productRows}
                </Table.Body>
            </Table>
        )

        return {
            key: type.name,
            title: type.description,
            content: { content: typeTable }
        }
    });
    if (isEmpty(productsFiltered)) {
        return (
            <Header as='h3'>No corporation shop in {formData.star} for {formData.currency}.</Header>
        );
    }

    return (
        <>
            <Header as='h3' style={{color: 'blue', margin: '0px'}} textAlign='center'>
                To receive your items please open a VCB ticket from the <a
                href="https://discord.com/channels/755947710351409255/787912146523652097">#how-to-use-vcb</a> channel on
                the VOID discord.
                <br/>
                <br/>
                How to get ISK from VC:
                <br/>
                Please contact your corp officers for possible alternative methods for avoiding contract tax. If that is
                not an option for you, then please send a 2 week in-game isk request contract to The Fiduciary.
                <br/>
                All requests for ISK sent to other characters will be rejected or left to expire.
                <br/>
                For any questions, please ask in <a
                href="https://discord.com/channels/755947710351409255/784198597524586546">#ama-void-bank</a> or open a
                VCB ticket.
            </Header>
            <Header as='h1' style={{margin: '0px'}} >Shopping Cart</Header>
            <Accordion panels={itemTypePanels} fluid styled />
        </>
    );
}

export default Cart;
