// React
import React from 'react';
import { Link } from 'react-router-dom';

// Libraries
import { get, isUndefined } from 'lodash';

// Styling and Semantics
import {
    Container,
    Image,
    Menu,
} from 'semantic-ui-react';

/**
 *  Navigation Component
 */
const Navigation = (props) => {
    // Property Descructure
    const { config: { localization, corp, authUser } } = props;

    const authItem = get(authUser, 'token') ? (
        <Menu.Item as={ Link } to='/' header>{get(authUser, 'pilot')}</Menu.Item>
    ) : ( '' );

    const discordItem = !isUndefined(corp) ? (
        <Menu.Item as='a' target="_blank" rel="noopener noreferrer" href={corp.discord}>{get(localization, 'navigation.discord')}</Menu.Item>
    ) : ( '' );

    const corpLogo = !isUndefined(corp) ? (
        <Image className='corp-logo' size='mini' src={corp.logo} />
    ) : ( '' );

    const corpName = !isUndefined(corp) ? corp.name : '';


    return (
        <Menu fixed='top' size='huge' inverted>
            <Container>
                <Menu.Item as={ Link } to='/' header>
                    {corpLogo}
                    {corpName}
                </Menu.Item>
                {authItem}
                {discordItem}
            </Container>
        </Menu>
    );
}

export default Navigation;
